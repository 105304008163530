import RootLayout from '@/components/RootLayout';
import { baseUrl, mountedd, adtyped, adviewd, isApp, popStatusd, stickyd, apiUrl, displayadd, dataloadd } from '@/libs/store';
import { useHookstate } from '@hookstate/core';
import {
  Box,
} from '@mui/material';
import Head from 'next/head';
import { withRouter } from 'next/router';
import { useEffect } from 'react';
import Script from 'next/script';
import axios from 'axios';
/* 
export const config = {
  runtime: 'experimental-edge',
};
 */
export async function getStaticProps() {

  var seoData = { apidata: [], apisidedata: { chain: [{ symbol: 'all', blockNumber: 0, coinUSD: 0 }], gainers: [], losers: [], newpair: [] } };
  try {
    //await axios.get(`${apiUrl}/tokenlist?chain=all&offset=0`).then(async (res) => {
    await fetch(`${apiUrl}/tokenlist?chain=all&offset=0`).then(async (res) => {
      res = { data: await res.json() };
      //console.log(res);
      var temp = res.data.data[0] && res.data.data[0].result ? res.data.data[0].result : [];
      var side = res.data.data[0] && res.data.data[0].side ? res.data.data[0].side : { chain: [{ symbol: 'all', blockNumber: 0, coinUSD: 0 }], gainers: [], losers: [], newpair: [] };
      await Promise.allSettled(temp.map(async (row, i) => {
        temp[i].id = i + 1;
      }))
      seoData.apidata = temp;
      seoData.apisidedata = side;
      //console.log("temp, side", temp, side);
    });
  } catch (e) {
    //console.log(e);
  }
  return {
    props: seoData,
    //revalidate: 120,
  }
}


export default withRouter(function Main({ router, apidata, apisidedata }) {

  const titleData = `Token List Multi Chain | DEX Tracker Tools`;
  const descriptionData = `CryptoGems is a DEX tracker tool that helps to find all DEX-related tokens and pairs traded in multiple chains. It helps to trade all markets from one place using advanced tools like charts, order history, volume, transaction count, market capital, etc.`;
  const mounted = useHookstate(mountedd);
  const dataload = useHookstate(dataloadd);
  const popStatus = useHookstate(popStatusd);
  const adviewable = useHookstate(adviewd);
  const adtype = useHookstate(adtyped);
  const sticky = useHookstate(stickyd);
  const displayad = useHookstate(displayadd);
  //const selAcc = useHookstate(sideaccord);
  useEffect(() => {

    if (mounted.get() || isApp) {
      return;
    } else {
      mounted.set(true);
      setTimeout(() => {
        dataload.set(true);
      }, 20000);
      setTimeout(() => {
        checkAdBlocker();
      }, 20000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);



  const checkAdBlocker = async () => {
    fetch("https://cdn.bmcdn6.com/js/64c8e7a64485b30b6fb7fecc.js?v=1692026187741", { //"https://cdn.bmcdn4.com/js/62f4e0ae6703276b08cd86e4.js?v=1690148231602"https://ad.a-ads.com/2189429?size=320x50
      method: "HEAD",
      mode: "no-cors",
      cache: "no-store",
    }).then((response) => {
      if (!adviewable.get() && displayad.get()) {
        adviewable.set(true);
      }
      if (localStorage.getItem("disclaimer")) {
        popStatus.set(false);
      } else {

      }
    }).catch((e) => {
      adviewable.set(false);
      popStatus.set(true);
    });
  }

  return (
    <Box sx={{ height: "100%", width: "100%", overflow: "auto" }}>
      <Head>
        <title>{titleData}</title>
        <meta property="og:title" content={titleData} />
        <meta name="description" content={descriptionData} />
        <link rel="canonical" href={baseUrl} />
        <meta property="og:url" content={baseUrl} />
        <meta property="og:description" content={descriptionData} />
        <meta name="twitter:title" content={titleData} />
        <meta name="twitter:description" content={descriptionData} />
      </Head>
      <Script id="bitad" strategy="lazyOnload" >
        {process.env.NODE_ENV !== 'development' && !isApp && displayad.get() ? `
            setTimeout(() => {
              !function(e,n,c,t,o,r,d){!function e(n,c,t,o,r,m,d,s,a){s=c.getElementsByTagName(t)[0],(a=c.createElement(t)).async=!0,a.src="https://"+r[m]+"/js/"+o+".js?v="+d,a.onerror=function(){a.remove(),(m+=1)>=r.length||e(n,c,t,o,r,m)},s.parentNode.insertBefore(a,s)}(window,document,"script","62f4e0ae6703276b08cd86e4",["cdn.bmcdn4.com"], 0, new Date().getTime())}();
              !function(e,n,c,t,o,r,d){!function e(n,c,t,o,r,m,d,s,a){s=c.getElementsByTagName(t)[0],(a=c.createElement(t)).async=!0,a.src="https://"+r[m]+"/js/"+o+".js?v="+d,a.onerror=function(){a.remove(),(m+=1)>=r.length||e(n,c,t,o,r,m)},s.parentNode.insertBefore(a,s)}(window,document,"script","62f4dba0e09b862c31169b6a",["cdn.bmcdn4.com"], 0, new Date().getTime())}();
              !function(e,n,c,t,o,r,d){!function e(n,c,t,o,r,m,d,s,a){s=c.getElementsByTagName(t)[0],(a=c.createElement(t)).async=!0,a.src="https://"+r[m]+"/js/"+o+".js?v="+d,a.onerror=function(){a.remove(),(m+=1)>=r.length||e(n,c,t,o,r,m)},s.parentNode.insertBefore(a,s)}(window,document,"script","62f4dbd592db9581236c78c5",["cdn.bmcdn6.com"], 0, new Date().getTime())}();
            }, 25000);
        `: ``}
      </Script>
      <RootLayout router={router} pagetype={"home"} chain={'all'} apidata={apidata} apisidedata={apisidedata} />
    </Box>
  )

})